/* eslint-disable */
import React from "react";

export default {
  en: {
    quote:
      "What truly sets Jawg apart is their partnership approach. If I ever face a critical issue, I know their team will be there to help me debug it.",
    author: "Florian Bruniaux, CTPO",
    sector: "Booking venues for events",
    interviewees: ["Florian Bruniaux, Chief Technical and Product Officer"],
    service: "Dynamic Maps, Geocoding",
    geo: "Europe",
    blocks: [
      {
        title: "Native Spaces",
        description: (
          <p>
            <a
              href="https://native-spaces.com/"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
              target="_blank"
            >
              Native Spaces
            </a>{" "}
            is a venue rental platform that enables professionals and
            individuals to organize events in unique locations. With thousands
            of available options and transparent pricing, the platform
            simplifies the process of booking venues tailored to any occasion.
          </p>
        ),
        img: "clientLogo",
      },
      {
        title: "",
        description: (
          <p>
            We spoke with Florian Bruniaux, Chief Technical and Product Officer
            (CTPO) at Native Spaces, who joined the company with the mission of
            completely revamping the product. One of his key objectives was
            optimizing the tools in use, including mapping, which represented a
            significant cost for the company.{" "}
            <q>Mapping was a considerable expense for Native Spaces.</q> By
            making the switch, they successfully reduced costs{" "}
            <q>
              from €600-700 per month with Google Maps to €100-200 with Jawg,
              not to mention the support that Jawg provided.
            </q>
          </p>
        ),
      },
      {
        title: "",
        description: (
          <p>
            This is not the first time Florian has shared his experience with
            Jawg Maps. Discover his other testimony on{" "}
            <a
              href="/fr/clients/explain"
              style={{ textDecoration: "underline" }}
              target="_blank"
            >
              eXplain
            </a>
            .
          </p>
        ),
      },
      {
        title: "Native Spaces & Jawg",
        description: (
          <>
            <p>
              Native Spaces has experienced rapid growth since its creation in
              2019-2020. After an initial MVP proved the viability of the
              concept and enabled a seed funding round, the technical team
              undertook a complete product overhaul to eliminate significant
              technical debt and build a more scalable and high-performance
              infrastructure.
            </p>
          </>
        ),
      },
      {
        title: "",
        description: (
          <p>
            At this critical stage, the objective was twofold: rebuild the
            platform while optimizing the costs of the tools used. Mapping, a
            key element of the user experience but also a significant expense,
            was naturally reconsidered. Jawg Maps emerged as a strategic
            alternative, offering an excellent cost/performance ratio and
            high-quality technical support. Already familiar with the solution,
            Florian Bruniaux was able to integrate Jawg quickly, also
            appreciating the fact that he was working with French technology.{" "}
            <q>
              We’ve known each other for a few years now, and I’ve always been
              happy with Jawg.
            </q>
          </p>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <p>
            While the adoption of Jawg Maps happened without hesitation, some
            senior developers needed time to adjust. Unlike Google Maps, which
            is omnipresent in technical documentation, Jawg required a deeper
            exploration. Once this transition was completed, the integration
            went smoothly.{" "}
            <q>
              The product does exactly what I need: a reliable, visually
              appealing map that just works; I’ve never struggled to make it do
              what I wanted.
            </q>
          </p>
        ),
      },
      {
        title: "",
        description: (
          <p>
            Beyond the technical solution, it was Jawg’s support that made the
            difference.{" "}
            <q>
              I had a responsive, available support team that understood me,
              knew my needs, and was eager to improve the product; something you
              don’t get with Google.
            </q>{" "}
            This direct access to the technical team allowed for seamless and
            efficient communication, creating a real partnership in optimizing
            and evolving the service. Because beyond the product,{" "}
            <q>it’s the team behind it that makes the difference.</q>
          </p>
        ),
      },
      {
        title: "",
        description: (
          <p>
            Thanks to this collaboration, Native Spaces was able to adopt a more
            flexible and cost-effective mapping alternative, without
            compromising on quality or performance.
          </p>
        ),
      },
      {
        title: "Jawg Maps in 3 words?",
        description: "Partner, efficient, collaboration",
      },
    ],
  },
  fr: {
    quote:
      "Ce qui fait vraiment la différence avec Jawg, c'est cette relation de partenariat. Si un jour j'ai un problème critique, je sais que l'équipe serait là pour m'aider à débugger.",
    author: "Florian Bruniaux, CTPO",
    sector: "Location de lieux pour événements",
    interviewees: ["Florian Bruniaux, Chief Technical and Product Officer"],
    service: "Dynamic Maps, Geocoding",
    geo: "Europe",
    blocks: [
      {
        title: "Native Spaces",
        description: (
          <p>
            <a
              href="https://native-spaces.com/"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
              target="_blank"
            >
              Native Spaces
            </a>{" "}
            est une plateforme de location d'espaces permettant aux
            professionnels et particuliers d'organiser leurs événements dans des
            lieux uniques. Avec des milliers d'options disponibles et des prix
            transparents, la plateforme simplifie la réservation de lieux
            adaptés à chaque occasion.
          </p>
        ),
        img: "clientLogo",
      },
      {
        title: "",
        description: (
          <p>
            Nous retrouvons Florian Bruniaux, Chief Technical and Product
            Officer (CTPO), qui a rejoint Native Spaces avec pour mission une
            refonte complète du produit. Son objectif : optimiser chaque outil
            utilisé, y compris la cartographie, un poste de dépense significatif
            pour l'entreprise.{" "}
            <q>
              &#160;La cartographie était un poste de dépense non négligeable à
              l'échelle de Native Spaces&#160;
            </q>
            . Grâce à ce changemen800t, ils ont pu passer{" "}
            <q>
              &#160;de 600/700€ par mois avec Google à 100/200€ avec Jawg, sans
              compter l'accompagnement que Jawg a pu fournir.&#160;
            </q>
          </p>
        ),
      },
      {
        title: "",
        description: (
          <p>
            Ce n'est d'ailleurs pas la première fois que Florian partage son
            expérience avec Jawg Maps. Découvrez également son témoignage sur{" "}
            <a
              href="/fr/clients/explain"
              style={{ textDecoration: "underline" }}
              target="_blank"
            >
              eXplain
            </a>
            .
          </p>
        ),
      },
      {
        title: "Native Spaces & Jawg",
        description: (
          <>
            <p>
              Native Spaces a connu une évolution rapide depuis sa création en
              2019-2020. Après un premier MVP qui a prouvé la viabilité du
              concept et permis une levée de fonds seed, l'équipe technique a
              entrepris une refonte complète du produit pour éliminer une dette
              technique importante et bâtir une infrastructure plus scalable et
              performante.
            </p>
          </>
        ),
      },
      {
        title: "",
        description: (
          <p>
            Dans cette phase critique, l'objectif était double : reconstruire la
            plateforme tout en optimisant les coûts des outils utilisés. La
            cartographie, élément clé de l'expérience utilisateur mais aussi
            poste de dépense significatif, a naturellement été remise en
            question. Jawg Maps s'est imposé comme une alternative stratégique,
            offrant un excellent rapport coût/performance et un accompagnement
            technique de qualité. Déjà familier avec la solution, Florian
            Bruniaux a pu intégrer Jawg rapidement, appréciant également le fait
            de travailler avec une technologie française.{" "}
            <q>
              &#160;Ça fait quelques années qu'on se connaît et j'en ai toujours
              été aussi content.&#160;
            </q>
          </p>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <p>
            Si l'adoption de Jawg Maps s'est faite sans hésitation, certains
            développeurs seniors ont eu besoin d'un temps d'adaptation.
            Contrairement à Google Maps, omniprésent dans la documentation
            technique, Jawg nécessitait une exploration plus poussée. Une fois
            cette transition réalisée, l'intégration s'est déroulée sans
            encombre.{" "}
            <q>
              &#160;Le produit fait juste ce que je lui demande : une carte qui
              marche bien, c'est joli, et je n'ai jamais galéré à faire ce que
              je voulais.&#160;
            </q>
          </p>
        ),
      },
      {
        title: "",
        description: (
          <p>
            Au-delà de la solution technique, c'est l'accompagnement de Jawg qui
            a fait la différence.{" "}
            <q>
              &#160;J'ai eu un support réactif, disponible, qui me comprenait,
              qui me connaissait et qui ne demande qu'à améliorer le produit, ce
              qui n'est pas le cas chez Google.&#160;
            </q>{" "}
            Cet accès direct à l'équipe technique a permis des échanges fluides
            et efficaces, avec un véritable partenariat dans l'optimisation et
            l'évolution du service. Car au-delà du produit,{" "}
            <q>&#160;c'est l'équipe derrière qui fait la différence.&#160;</q>
          </p>
        ),
      },
      {
        title: "",
        description: (
          <p>
            Grâce à cette collaboration, Native Spaces a pu adopter une
            alternative cartographique plus flexible et économiquement
            avantageuse, sans compromis sur la qualité ou la performance.
          </p>
        ),
      },
      {
        title: "Jawg Maps en 3 mots ?",
        description: "Partenaire, Efficient, Collaboration",
      },
    ],
  },
};
